import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Linux`}</h1>
    <h2>{`Get build info`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`uname -a
# Linux dark-side 5.6.14-300.fc32.x86_64 #1 SMP Wed May 20 20:47:32 UTC 2020
# x86_64 x86_64 x86_64 GNU/Linux
`}</code></pre>
    <h2>{`Get kernel version`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`uname -r
# 5.6.14-300.fc32.x86_64
`}</code></pre>
    <h2>{`See what services are available`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`ll /etc/systemd/system
`}</code></pre>
    <h2>{`See what services are running`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`journalctl -ef
`}</code></pre>
    <h2>{`See what is machine public ip`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl ipinfo.io/ip
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      